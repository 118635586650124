@font-face {
  font-display: swap;
  font-family: 'Circular';
  font-weight: 700;
  src: url(../public/fonts/lineto-circular-bold.woff2) format('woff2'),
    url(../public/fonts/lineto-circular-bold.woff) format('woff'),
    url(../public/fonts/Circular-Bold.ttf) format('truetype'),
    url(../public/fonts/lineto-circular-bold.eot) format('eot');
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  font-weight: 500;
  src: url(../public/fonts/lineto-circular-medium.woff2) format('woff2'),
    url(../public/fonts/lineto-circular-medium.woff) format('woff'),
    url(../public/fonts/Circular-Medium.ttf) format('truetype'),
    url(../public/fonts/lineto-circular-medium.eot) format('eot');
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  font-weight: normal;
  src: url(../public/fonts/lineto-circular-book.woff2) format('woff2'),
    url(../public/fonts/lineto-circular-book.woff) format('woff'),
    url(../public/fonts/Circular-Book.ttf) format('truetype'),
    url(../public/fonts/lineto-circular-book.eot) format('eot');
}

.tabs {
  margin-bottom: 20px;
}

.picker {
  margin-left: 20px;
}

.timeline {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .timeline {
    font-size: 14px;
  }
}

.timeline-phase {
  padding-bottom: 5px;
  padding-top: 10px;
}

.timeline-task {
  display: flex;
  flex-direction: row;
  height: 20px;
}

@media (max-width: 767px) {
  .timeline-task {
    justify-content: space-between;
  }
}

.timeline-task-type {
  padding-right: 20px;
  min-width: 360px;
}
@media (max-width: 767px) {
  .timeline-task-type {
    min-width: auto;
  }
}

.timeline-task-state {
  align-self: flex-end;
}

.action {
  align-items: center;
  display: flex;
  padding: 5px 0;
}

.ra-field.action {
  border-bottom: 1px solid #ccc;
}

@media (max-width: 767px) {
  .action {
    align-items: flex-start;
    flex-direction: column;
  }
}

.action-data {
  display: flex;
}

@media (max-width: 767px) {
  .action-data .picker {
    margin-bottom: 10px;
    margin-left: 0;
  }
}

.action-data-first {
  margin-right: 10px;
}

.blank {
  width: 24px;
}

.action-submit {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.snackbar-message {
  display: flex;
  align-items: center;
}

.snackbar-icon {
  margin-right: 5px;
}
