.DropZone-container {
  align-items: center;
  background-color: #fafafa;
  border-radius: 2;
  border: 2px dashed #eee;
  color: #bdbdbd;
  display: flex;
  flex-direction: column;
  flex: 1;
  outline: none;
  padding: 20px;
  transition: border 0.24s ease-in-out;
}

.DropZone-container .focused {
  border-color: #2196f3;
}

.DropZone-container .accept {
  border-color: #00e676;
}

.DropZone-container .reject {
  border-color: #ff1744;
}
