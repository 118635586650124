.Chats-divider {
  margin: 16px 0;
}

.Chats-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.Chats-row:hover {
  background-color: #f2f2f2;
}

.Chats-cell {
  cursor: pointer;
}

.Chats-entry--unread {
  font-weight: bold;
}

.Chats-refresh {
  margin-bottom: 16px;
}

.Chats-mobileContent {
  background-color: #eee;
  border-radius: 10px;
  margin-top: 8px;
  padding: 8px;
}
